
import { Component, Vue, Prop } from "vue-property-decorator";
import { ProgressCircle } from "vue-progress-circle";

import unitRoutes from "@/api/routes/units";

interface IFileData {
  readonly data: string;
  readonly file_name: string;
}

@Component({
  name: "CFileDownload",
  components: {
    ProgressCircle
  }
})
export default class CFileDownload extends Vue {
  @Prop({ required: true }) protected url!: string;
  @Prop({ required: false, default: "" }) protected classLink!: string;
  @Prop({ required: false }) protected token?: string;
  @Prop({ required: false }) protected disabled?: boolean;

  @Prop({ required: false, default: false })
  protected showCancelButton!: boolean;

  protected fileUrl: any = this.$api.cdn_url + this.url;

  protected inProgress = false;
  protected canceled = false;

  protected progress = 0;

  protected created() {
    this.fileUrl = RegExp(this.$api.regex_url).test(this.url)
      ? this.url
      : this.$api.cdn_url + this.url;
  }

  public async getFile() {
    if (this.disabled != null && this.disabled) {
      return;
    }

    this.canceled = false;
    this.inProgress = true;
    this.progress = 0;
    this.fakeProgressMove();

    const fileLink = document.createElement("a");

    return this.$api
      .get(
        this.url.includes("https://monxweb.baltika.ru")
          ? unitRoutes.document_download
          : this.url,
        {
          params: { file_url: this.url }
        }
      )
      .then(async ({ data: res }: { data: IFileData }) => {
        if (this.canceled) {
          return;
        }

        fileLink.href = res.data;
        fileLink.download = res.file_name;
        fileLink.target = "_blank";

        fileLink.click();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.inProgress = false;

        fileLink.remove();
      });
  }

  protected cancel() {
    this.canceled = true;
    this.inProgress = false;
  }

  private fakeProgressMove() {
    this.progress += (1 - this.progress) / 5;

    if (this.inProgress) {
      setTimeout(() => {
        this.fakeProgressMove();
      }, 300);
    }
  }
}
